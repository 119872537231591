import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import { LinearProgress } from '@mui/material';

const AuthWrapper = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const isLoginPage = window.location.pathname === '/';

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isAuthenticated && !isLoginPage) {
    navigate('/');
  }

  if (isAuthenticated && isLoginPage) {
    navigate('/account');
  }

  return <>{children}</>;
};

export default AuthWrapper;
